/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { useRequestURL } from '#app'
import { useTrackEvent } from '#imports'
import { useHead, useSeoMeta } from 'unhead'
import { reactive } from 'vue'
import useI18n from '~/src/UserInterface/App/composables/Translation/useI18n'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

interface MetaOptions {
  title: string
}

export enum MetaDomain {
  User,
  Organisation,
  Engagement,
  None,
}

export default (options: MetaOptions, metaDomain: MetaDomain = MetaDomain.None) => {
  let title = options.title

  const requestUrl = useRequestURL()
  const eventParams = reactive<Record<string, string | undefined>>({
    app_name: 'app.analytikal.nl',
    page_location: requestUrl.href,
    page_path: requestUrl.pathname,
    page_title: title,
    organisation: undefined,
    engagement: undefined,
  })

  const { hasOrganisation, getOrganisation } = useOrganisationState()
  if (hasOrganisation.value) {
    eventParams.organisation = getOrganisation().value['@id'].toString()

    if (metaDomain === MetaDomain.Organisation) {
      title = `${title} | ${getOrganisation().value.name}`
    }
  }

  const { hasCurrentEngagement, getCurrentEngagement } = useEngagementState()
  if (hasCurrentEngagement.value) {
    eventParams.engagement = getCurrentEngagement().value['@id'].toString()

    if (metaDomain === MetaDomain.Engagement) {
      title = `${title} - ${getCurrentEngagement().value.name}`
    }
  }

  if (metaDomain === MetaDomain.User) {
    const { getUser, isAuthenticated } = useUserState()
    if (isAuthenticated.value) {
      title = `${title} - ${getUser().firstName} ${getUser().lastName}`
    }
  }

  useHead({
    title: `${title} | Analtyikal`,
  })

  const { locale } = useI18n()
  useSeoMeta({
    title: `${title} | Analtyikal`,
    ogTitle: options.title,
    ogUrl: requestUrl.href,
    ogLocale: locale.value,
    ogSiteName: 'Analytikal',
  })

  useTrackEvent('page_view ', {
    ...eventParams,
    page_title: title,
  })
}
